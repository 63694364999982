<template>
  <div class="establishment-menu">
    <a-form :form="form">
      <a-row type="flex">
        <a-col :flex="12" class="search-bar">
          <a-input-search
            placeholder="Buscar"
            v-model="searchField"
            @search="onSearch"
          />
        </a-col>
        <a-col :flex="12" class="selected-menu">
          <a-select
            style="width: 80%"
            @change="changedMenu"
            :default-value="selectedMenu"
            v-if="loaded"
          >
            <a-select-option
              v-for="(section, index) in sections"
              v-bind:key="index"
              :value="index"
              :class="
                selectedMenu == index
                  ? 'ant-select-dropdown-menu-item-selected'
                  : ''
              "
              :aria-selected="selectedMenu == index ? 'true' : ''"
            >
              {{ section.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row type="flex">
        <a-col class="add-section" :span="24">
          <div class="btn-add-section">
            <span @click="addSection()"> Añadir sección </span>
          </div>
        </a-col>
      </a-row>
    </a-form>
    <span class="empty-menu-legend" v-if="isEmptyMenu">
      Menú vacío
    </span>
    <a-collapse :expand-icon-position="expandIconPosition">
      <a-collapse-panel
        v-for="(element, index) in menuEstablishment"
        :key="index"
        :header="element.name"
      >
        <div
          class="product-container"
          v-for="(product, index) in element.products"
          :key="index"
        >
          <div class="product">
            <p class="product-name">
              {{ product.name }}
              <a-icon
                class="product-edit"
                type="edit"
                @click="onEdit(product)"
              /> 
            </p>
            <a-row type="flex">
              <a-col :flex="3">
                <p class="product-description">{{ product.description }}</p>
                <a-row type="flex">
                  <div class="pricing-container">
                    Precio:
                    <p class="product-pricing">
                      ${{ product.pricing }}
                    </p>
                  </div>
                  <div class="pricing-container pricing-establishment">
                    Precio Local:
                    <p class="product-pricing">
                      ${{ getProductQrPricing(product) }}
                    </p>
                  </div>
                </a-row>
              </a-col>
              <a-col
                v-if="isAvailable(product.available)"
                class="product-stock"
                :flex="3"
              >
                <p @click="switchAvailability(product)">Disponible</p>
              </a-col>
              <a-col v-else class="product-out-stock" :flex="3">
                <p @click="switchStatus(product.id)">Agotado</p>
              </a-col>
            </a-row>
            <a-row v-if="hasExtras(product)">
              <a-collapse
                class="product-extras"
                :expand-icon-position="expandIconPosition"
              >
                <a-collapse-panel
                  v-for="(option, index) in product.options"
                  :key="index"
                  :header="option.list.description"
                >
                  <div
                    class="product"
                    v-for="(extra, index) in option.list.extras"
                    :key="index"
                  >
                    <a-row type="flex">
                      <a-col :flex="3">
                        <p class="product-name">{{ extra.name }}</p>
                      </a-col>
                      <a-col
                        v-if="isAvailableExtra(extra, option)"
                        class="product-stock"
                        :flex="3"
                      >
                        <p
                          @click="
                            switchExtraStatus(extra, 0, option, option.list)
                          "
                        >
                          <a-icon
                            v-if="indexClicked === extra.id"
                            type="loading"
                          />
                          Disponible
                        </p>
                      </a-col>
                      <a-col v-else class="product-out-stock" :flex="3">
                        <p
                          @click="
                            switchExtraStatus(extra, 1, option, option.list)
                          "
                        >
                          <a-icon
                            v-if="indexClicked === extra.id"
                            type="loading"
                          />
                          Agotado
                        </p>
                      </a-col>
                    </a-row>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-row>
          </div>
          <a-divider />
        </div>
        <a-row type="flex" class="section-options">
          <a-popconfirm
            @confirm="deleteSection(element)"
            @cancel="() => {}"
            title="¡Recuerda que se borrarán los productos!"
            okText="Confirmar"
            cancelText="Cancelar"
          >
            <div class="product-options">
              ELIMINAR SECCIÓN
              <a-icon type="delete"></a-icon>
            </div>
          </a-popconfirm>

          <a-divider type="vertical"></a-divider>

          <div class="product-options" @click="updateSection(element)">
            EDITAR SECCIÓN
            <a-icon type="edit"></a-icon>
          </div>

          <a-divider type="vertical"></a-divider>

          <div class="product-options" @click="addProduct(element.id)">
            AGREGAR PRODUCTO
            <a-icon type="plus"></a-icon>
          </div>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
    <Error
      :visible="isErrorVisible"
      :message="errorMessage"
      v-on:cancel="errorVisible = false"
      :iconType="iconType"
    />
    <PriceEdit
      :visible="isPriceEditVisible"
      :product="selectedProduct"
      v-on:cancel="priceEditVisible = false"
      v-on:submitProduct="onSaveChanges"
    />
    <AvailabilityEdit
      :visible="isAvailabilityEditVisible"
      :product="selectedProduct"
      v-on:cancel="availabilityEditVisible = false"
      v-on:confirm="onChangeAvailability"
    />
    <AddSection
      :visible="isAddSectionVisible"
      :section="selectedSection"
      v-on:cancel="addSectionVisible = false"
      v-on:confirm="onAddSection"
    />
    <AddProduct
      :visible="isAddProductVisible"
      v-on:cancel="addProductVisible = false"
      v-on:confirm="onAddProduct"
    />
  </div>
</template>
<script>
import Error from "@/components/modal/Error";
import errorM from "@/utils/errors.js";
import PriceEdit from "../../components/menu/PriceEdit.vue";
import AvailabilityEdit from "../../components/menu/AvailabilityEdit";
import AddSection from "@/components/menu/add/AddSection";
import AddProduct from "@/components/menu/add/AddProduct";
export default {
  name: "EstablishmentMenu",
  components: {
    AddProduct,
    AddSection,
    AvailabilityEdit,
    Error,
    PriceEdit,
  },
  data() {
    return {
      addProductVisible: false,
      addSectionVisible: false,
      availabilityEditVisible: false,
      selectedMenu: "",
      editingSection: false,
      estMenu: "",
      loaded: false,
      menu: Object,
      menuFiltered: Object,
      sections: Object,
      expandIconPosition: "left",
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
      searchField: "",
      errorVisible: false,
      errorMessage: "",
      indexClicked: undefined,
      iconType: 0,
      priceEditVisible: false,
      selectedProduct: null,
      selectedSection: {
        name: "",
      },
      onEditProduct: null,
    };
  },
  created() {
    this.loadMenu();
  },
  methods: {
    getProductQrPricing(product) {
      return product.qr_pricing || "0.0";
    },
    loadMenu() {
      this.updateMenuStore("");
      this.loaded = true;
    },
    updateMenuStore(searchValue) {
      this.estMenu =
        this.$store.getters.establishmentMenu[this.getSelectedMenu] || this.$store.getters.establishmentMenu[0];
      this.selectedMenu = this.estMenu.name;
      this.sections = this.$store.getters.establishmentMenu;
      this.menu = this.sections.find(
        (x) => x.name === this.selectedMenu
      ).sections;
      this.menuFiltered = this.menu;
      if (searchValue !== "") {
        this.onSearch(searchValue);
      }
      return this.menuFiltered;
    },
    isAvailable(available) {
      return available === 0 || available === null ? false : true;
    },
    isAvailableExtra(extra, option) {
      if (this.selectedProduct && this.selectedProduct.options > 0) {
        return this.selectedProduct.options
          .find(o => o.id === option.id).list.extras
          .find(e => e.id === extra.id).available;
      }
      return extra.available === 0 || extra.available === null ? false : true;
    },
    changedMenu(value) {
      this.$store.commit("set_selected_menu", value);
      //let menu = this.sections.find((x) => x.name === value);
      //this.menu = typeof menu !== "undefined" ? menu.sections : "undefined";
    },
    onEdit(product) {
      this.selectedProduct = product;
      this.onEditProduct = product;
      this.priceEditVisible = true;
    },
    onSearch(value) {
      value = value.toLowerCase();
      if (value !== "") {
        this.menuFiltered = this.menu
          .filter(
            (x) =>
              x.products.filter((p) => p.name.toLowerCase().includes(value))
                .length > 0
          )
          .map((x) => {
            return {
              ...x,
              products: x.products.filter((p) =>
                p.name.toLowerCase().includes(value)
              ),
            };
          });
      } else {
        this.menuFiltered = this.menu;
      }
    },
    hasExtras(product) {
      return product.options.length > 0;
    },
    showModal(section, item) {
      item.section = section;
      this.item.data = item;
      this.item.show = true;
    },
    updateMenu(updatedMenu) {
      //Update menu in Vuex store
      this.$store.commit(
        "set_menu",
        this.$store.getters.establishmentMenu.map((m) => {
          let menu = Object.assign({}, m);
          if (m.name == this.selectedMenu) {
            menu.sections = updatedMenu;
          }
          return menu;
        })
      );
      //Update the menu
      this.menu = updatedMenu;

      //We need to update the menuFiltered since is the one that is currently shown
      this.onSearch(this.searchField);
    },
    updateExtras(list, resp, extraId) {
      //Update the status of the extras within a list
      let extras = list.extras.map((e) => {
        const availability = Object.assign({}, e);
        if (availability.id == extraId) {
          availability.available = resp;
        }
        return availability;
      });
      list.extras = extras;
      return list;
    },
    switchAvailability(product) {
      this.selectedProduct = product;
      this.availabilityEditVisible = true;
    },
    updateMenuStatusOnChange(productId, available) {
      return this.menu.map((x) => {
        return {
          ...x,
          products: x.products.map((p) => {
            const availability = Object.assign({}, p);
            if (availability.id == productId) {
              availability.available = available ? 1 : 0;
            }
            return availability;
          }),
        };
      });
    },
    addSection() {
      this.editingSection = false;
      this.selectedSection.name = "";
      this.addSectionVisible = true;
    },
    updateSection(section) {
      this.editingSection = true;
      this.selectedSection = section;
      this.addSectionVisible = true;
    },
    async deleteSection(section) {
      await this.$store.dispatch(
        "delete",
        ["section", section.id].join("/")
      );

      this.estMenu.sections = this.estMenu.sections.filter((s) => {
        return s.id != section.id;
      });
    },
    async switchExtraStatus(extra, status, product, list) {
      try {
        this.indexClicked = extra.id;
        const data = {
          extraId: extra.id,
          productId: product.product_id,
          listId: list.id,
        };
        const statusm = await this.$store.dispatch("post", {
          route: "switch-extra-availability-electron",
          data: data,
        });
        const updatedMenu = this.menu.map((x) => {
          return {
            ...x,
            products: x.products.map((p) => {
              return {
                ...p,
                options: p.options.map((o) => {
                  return {
                    ...o,
                    list: this.updateExtras(o.list, status, extra.id),
                  };
                }),
              };
            }),
          };
        });
        if (!statusm.ok) {
          this.iconType = 1;
          this.errorMessage = errorM.availability.productExtraUpdated(
            statusm.updatedProducts,
            list.description
          );
          this.errorVisible = true;
        }
        this.updateMenu(updatedMenu);
      } catch (err) {
        console.log(err);
      }
      this.indexClicked = undefined;
    },
    async switchStatus(productId) {
      let resp;
      try {
        resp = await this.$store.dispatch("post", {
          route: ["switch-product-availability-electron", productId].join("/"),
          data: null,
        });
        if (resp.success) {
          this.updateMenu(
            this.updateMenuStatusOnChange(productId, resp.available)
          );
        } else {
          this.iconType = 0;
          this.errorMessage = errorM.availability["NO_AVAILABILITY_ERROR"];
          this.errorVisible = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async onAddSection(section) {
      this.addSectionVisible = false;
      let resp;

      const payload = {
        name: section.name,
        menu_id: this.estMenu.id,
      };
      try {
        if (this.editingSection) {
          await this.$store.dispatch("put", {
            route: ["section", this.selectedSection.id].join("/"),
            data: payload,
          });
        } else {
          resp = await this.$store.dispatch("post", {
            route: "section",
            data: payload,
          });

          this.estMenu.sections.unshift(resp);
        }

        this.$notification.success({
          message: "Agregar sección",
          description: `Se ha agregado la sección ${section.name}`,
          duration: 5,
        });
      } catch (err) {
        this.$notification.success({
          message: "Agregar sección",
          description: `Error al agregar sección ${section.name}`,
          duration: 5,
        });
        console.log(err);
      }
    },
    async onAddProduct(data) {
      this.addProductVisible = false;
      let resp;
      const payload = {
        ...data,
        section_id: this.selectedSection,
      };
      try {
        resp = await this.$store.dispatch("post", {
          route: "product",
          data: payload,
        });

        if (!("options" in resp)) {
          resp.options = [];
        }
        this.estMenu.sections
          .find((s) => s.id == this.selectedSection)
          .products.push(resp);
      } catch (err) {
        console.log(err);
      }
    },
    async onChangeAvailability(data) {
      this.availabilityEditVisible = false;

      let resp;
      try {
        resp = await this.$store.dispatch("post", {
          route: [
            "switch-product-availability-electron",
            data.id,
            data.availability_type,
          ].join("/"),
          data: null,
        });
        if (resp.success) {
          this.updateMenu(
            this.updateMenuStatusOnChange(data.id, resp.available)
          );
        } else {
          this.iconType = 0;
          this.errorMessage = errorM.availability["NO_AVAILABILITY_ERROR"];
          this.errorVisible = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async addProduct(sectionId) {
      this.selectedSection = sectionId;
      this.addProductVisible = true;
    },
    async onSaveChanges(productChanges) {
      this.priceEditVisible = false;
      const data = {
        name: productChanges.product.name,
        description: productChanges.product.description,
        pricing: productChanges.product.pricing,
        qr_pricing: productChanges.product.qr_pricing
      }
      try {
        await this.$store.dispatch("put", {
          route: ["product", this.selectedProduct.id].join("/"),
          data: data,
          method: "PUT",
        });
        const product = productChanges.product;
        this.onEditProduct.name = product.name;
        this.onEditProduct.description = product.description;
        this.onEditProduct.pricing = product.pricing;
        this.onEditProduct.qr_pricing = product.qr_pricing;
        this.selectedProduct = null;
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    getSelectedMenu() {
      return this.$store.getters.getSelectedMenu;
    },
    menuEstablishment() {
      return this.updateMenuStore(this.searchField);
    },
    isAvailabilityEditVisible() {
      return !this.$store.getters.isNewOrder && this.availabilityEditVisible;
    },
    isErrorVisible() {
      return !this.$store.getters.isNewOrder && this.errorVisible;
    },
    isPriceEditVisible() {
      return !this.$store.getters.isNewOrder && this.priceEditVisible;
    },
    isAddSectionVisible() {
      return !this.$store.getters.isNewOrder && this.addSectionVisible;
    },
    isAddProductVisible() {
      return !this.$store.getters.isNewOrder && this.addProductVisible;
    },
    isEmptyMenu() {
      return this.menuEstablishment.length == 0;
    },
  },
};
</script>
